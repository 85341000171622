<template>
  <div class="full_container">
    <div class="center vertical_center full_height">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppErrorLayout',
  };
</script>
